<template lang="pug">
.row.align-items-center
  .col-lg-6.col-md-12.col-12
    .product-images
      #imageModal.modal.modal-xl.fade(tabindex="-1", aria-labelledby="imageModalLabel", aria-hidden="true")
        .modal-dialog
          .modal-content
            .modal-header
              button.btn-close(type="button", data-bs-dismiss="modal", aria-label="Close")
            .modal-body
              inner-image-zoom(:src="currentImg" :zoomScale="2.5" v-if="currentImg")
      .single-product: .product-image: .main-img.image-container
        img(:src="currentImg", data-bs-toggle="modal", data-bs-target="#imageModal")
        span.sale-tag(v-if="currentProduct('discount')") -{{currentProduct('discount')}}%
        span.new-tag(v-else-if="currentProduct('new')") NEU
        span.bio-tag(v-else-if="currentProduct('new')") BIO
        span.vegan-tag(v-else-if="currentProduct('vegan')") VEGAN


      .images
        .image-container(v-if="currentProduct('img_1').length"): img.img(:src="currentProduct('img_1')" @click="currentImg = currentProduct('img_1')")
        .image-container(v-if="img_2.length"): img.img(:src="img_2" @click="currentImg = img_2")
        .image-container(v-if="img_3.length"): img.img(:src="img_3" @click="currentImg = img_3")
        .image-container(v-if="img_4.length"): img.img(:src="img_4" @click="currentImg = img_4")
        .image-container(v-if="img_5.length"): img.img(:src="img_5" @click="currentImg = img_5")
  .col-lg-6.col-md-12.col-12
    div(v-html="innerHtml")
    .product-info(v-if="canBuy")
      
      h4.text-warning(v-if="currentProduct('deviating_delivery')") {{currentProduct('deviating_delivery')}}
      h4.text-success(v-else) Auf Lager.
      h3.price.mt-2 
        | {{numberToCurrency(currentProduct('price'))}}
        span(v-if="currentProduct('original_price')") {{numberToCurrency(currentProduct('original_price'))}}
      .info-text(style="margin-top: -10px;")
        small {{currentProduct('price_per_100')}}inklusive {{currentProduct('tax')}}% MwSt., zuzüglich Versandkosten.
      form(:action="region_path + product.card_path", method="post")
        input(name="authenticity_token" :value="authenticity_token" type="hidden")
        input(name="variant_id" :value="variant_id" type="hidden")
        .form-group(v-for="(options, name) in variantSelects")
          label(:for="'variant-input'+name") {{name}}
          select.form-control(:id="'variant-input'+name" name="variants[]" @change="(e) => selectedVariants[name] = e.target.value" :value="selectedVariants[name]")
            option(v-for="o in options" :value="o.id") {{o.variant}}
        
        .row.mt-2
          .col-lg-4.col-md-4.col-12
            .form-group.quantity
              label(for="quantity") Anzahl
              select#quantity.form-control(name="quantity")
                option(v-for="n in 10" :disabled="currentProduct('stock') != '' && currentProduct('stock') < n") {{n}}
          .col-lg-8.col-md-4.col-12
            .button.cart-button.mt-5
              button.btn(style="width: 100%;", type="submit") In den Einkaufswagen
      h5.mt-5.mb-3(v-if="locations.length != 0")
        | Vorort Abholung
        svg(xmlns="http://www.w3.org/2000/svg", viewBox="0 0 24 24" style="width: 18px; height: 18px; margin-left: 10px;" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="Du kannst dieses Produkt an einem Verfügbaren Standort abholen, dazu wähle vor dem bezahlen die Abholoption aus.")
          path(fill="currentColor" d="M15.07,11.25L14.17,12.17C13.45,12.89 13,13.5 13,15H11V14.5C11,13.39 11.45,12.39 12.17,11.67L13.41,10.41C13.78,10.05 14,9.55 14,9C14,7.89 13.1,7 12,7A2,2 0 0,0 10,9H8A4,4 0 0,1 12,5A4,4 0 0,1 16,9C16,9.88 15.64,10.67 15.07,11.25M13,19H11V17H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z")
      .text-success(v-for="l in locations")
        svg(style="width: 18px; height: 18px; margin-top: -3px;", viewBox="0 0 24 24", xmlns="http://www.w3.org/2000/svg")
          path(d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z", fill="currentColor")
        span(v-html="l")
    .product-info(v-else)
      h4.text-danger Derzeit nicht verfügbar
      .form-group(v-for="(options, name) in variantSelects")
        label {{name}}
        select.form-control(name="variants[]" @change="(e) => selectedVariants[name] = e.target.value" :value="selectedVariants[name]")
          option(v-for="o in options" :value="o.id") {{o.variant}}
      h4.mt-5.mb-4 Erhalte eine E-Mail, sobald das Produkt wieder verfügbar ist.
      form(:action="region_path + '/product/subscribe'", method="post").button: .input-group.mb-3
        input(name="product_subscription[product_id]" :value="product.id" type="hidden")
        input(name="product_subscription[unstocked_variant_ids]" :value="unStockedVariantIds.join(',')" type="hidden")
        input(name="authenticity_token" :value="authenticity_token" type="hidden")
        input.form-control#product_subscription_email(type="email", placeholder="E-Mail Adresse" name="product_subscription[email]")
        button.btn(type="submit") Absenden
</template>

<script>
import * as bootstrap from 'bootstrap'
import InnerImageZoom from 'vue-inner-image-zoom';
export default {
  components: {
    InnerImageZoom,
  },
  props: [
    'img_1',
    'img_2',
    'img_3',
    'img_4',
    'img_5',
    'innerHtml',
    'authenticity_token',
    '_product',
    '_locations',
    '_variants',
    'region_path'
  ],
  data () {
    return {
      currentImg: null,
      product: {},
      selectedVariants: {},
      locations: [],
      variants: [],
      variant_id: null,
      email: null,
      unStockedVariantIds: []
    }
  },
  mounted () {
    this.product = JSON.parse(this._product)
    this.currentImg = this.product.img_1
    this.locations = JSON.parse(this._locations)
    this.variants = JSON.parse(this._variants)

    Object.entries(this.variantSelects).forEach((o) => {
      let variants = o[1].filter( x => x.stocked)
      if (variants.length == 0) variants = o[1]

      this.selectedVariants[o[0]] = variants.sort(function(a, b) {
        return - (a.base - b.base)
      })[0].id.toString()
    })

    setTimeout(() => {
      const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
      const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
     }, 100)
  },
  watch: {
    selectedVariants: {
      handler(newVal) {
        this.variant_id = this.variantBy('price').id
        this.currentImg = this.currentProduct('img_1')
      },
      deep: true
    },
  },
  computed: {
    canBuy(){
      if (this.selectedVariants && Object.values(this.selectedVariants).length != 0){
        this.unStockedVariantIds = []
        const variants = this.variants.filter(x => {
          let isPresent = Object.values(this.selectedVariants).indexOf(x.id.toString()) != -1
          if (isPresent && !x.stocked) this.unStockedVariantIds.push(x.id) 
          return isPresent && x.stocked
        })
        return this.unStockedVariantIds.length === 0
      }else{
        return this.product.stocked
      }
    },
    variantSelects(){
      var groupBy = function(xs, key) {
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };

      return groupBy(this.variants, "difference")
    },
  },
  methods: {
    variantBy(field){
      return this.variants.filter(x => Object.values(this.selectedVariants).indexOf(x.id.toString()) != -1 ).find(x => x[field]) || {}
    },
    currentProduct(field){
      const variant = this.variantBy(field)
      const p = variant[field] || this.product[field]
      if (p === 0){
        return p
      } else {
        return p || ""
      }
    },
    numberToCurrency(x){
      return Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(x)
    },
  }
}
</script>

<style scoped lang="scss">
</style>